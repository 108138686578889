<template>
  <div>
    <HeaderPayment/>
    <div class="misc-wrapper">
      <div>
        <b-container class="mt-2 col-sm-12 mb-5 flex-fill container">
          <b-row>

            <!-- 1ra columna -->

            <b-col class="col-md-6 col-sm-12 col-lg-6  col-xl-5 columna">
              <div v-if="bookingDetail">

                <!-- Purchased Products -->
                <b-card tag="article" class="mb-2 rounded-0">
                  <!-- Products -->
                  <template #header>
                    <div class="col-md-12">
                      <h4 class="listPayTitle mb-0" style="0.75s infinite ease-in-out">
                        Purchased Products
                      </h4>
                    </div>
                  </template>
                  <!-- Detail -->
                  <table class="table animate__animated animate__bounce">
                    <tbody>
                      <tr>
                        <td class="text-small-cart" :id="'room'+bookingDetail.id">
                          <span v-if="bookingDetail.datein">

                            <span class="font-weight-bolder" style="font-size: 16px">
                              {{ bookingDetail.roomtypename }}
                            </span>
                            <div v-if="bookingDetail.orderdetail.balance == 0.00" class="text-center float-right">
                              <label><i>This booking is already paid</i></label>
                            </div>
                            <div class="text-center float-right" v-else-if="isloadingTransactions">
                              <b-spinner label="Loading..." small variant="success"/>
                            </div>

                            <table class="content booking" >
                              <tr></tr>
                              <tr>
                                <td>
                                  <span class="font-weight-bolder">Check-In:</span>
                                  <span style="">{{ bookingDetail.datein }}</span>
                                </td>

                                <td>
                                  <span class="font-weight-bolder">Check-Out:</span>
                                  <span style="">{{ bookingDetail.dateout }}</span>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <span class="font-weight-bolder">Nights:</span>
                                  {{ bookingDetail.nights }}
                                </td>
                                <td>
                                  <span class="font-weight-bolder">Adults:</span>
                                  {{ bookingDetail.adults }}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <span class="font-weight-bolder">Childrens:</span>
                                  {{ bookingDetail.children }}
                                </td>
                              </tr>
                            </table>

                            <table class="content booking" v-if="bookingDetail.datein">
                              <tr>
                                <td>
                                  <span class="font-weight-bolder">Plan:</span>
                                  {{ bookingDetail.mealplancode }}
                                </td>
                                <td>
                                  <span class="font-weight-bolder">Balance:</span>
                                  $ {{ bookingDetail.orderdetail.balance }} {{ bookingDetail.orderdetail.currency_code }}
                                </td>
                              </tr>
                            </table>
                          </span>
                          <span v-else class="">
                            <span class="titleProduc font-weight-bolder">
                              {{ bookingDetail.bookingDetailsold }}
                            </span>

                            <span class="display-inline font-weight-bolder">
                              Operation Date:
                            </span>
                            <span class="display-inline">
                              {{ bookingDetail.operationdate }}
                            </span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- Detail end -->
                </b-card>

                <!-- Totals -->
                <article class="card mb-2 rounded-0">
                  <div class="card-body">
                    <table class="table animate__animated animate__bounce" v-if="currencyValue">
                      <tbody>
                        <h4 class="listPayTitle">Totals</h4><hr>
                        <tr class="text-small-cart row">
                          <span class="col-6" style="text-align: right;margin: 0 0px 0px -11px;">
                            <span class="text-right">Initial Payment:</span>
                            $ {{(bookingDetail.orderdetail.firstpay * bookingDetail.orderdetail.currency_value).toFixed(2)}}
                            {{ bookingDetail.orderdetail.currency_code }}
                          </span>
                          <span class="col-6"></span>
                        </tr>
                        <hr/>
                        <tr class="text-small-cart row">
                          <span class="col-6">
                            <span class="font-weight-bolder">Total Balance:</span>
                            $ {{ (bookingDetail.total * bookingDetail.orderdetail.currency_value).toFixed(2) }}
                            {{ bookingDetail.orderdetail.currency_code }}
                          </span>
                          <span class="col-6">
                            <span class="font-weight-bolder"> Total paid:</span>
                            $ {{ (sum * bookingDetail.orderdetail.currency_value).toFixed(2) }}
                            {{ bookingDetail.orderdetail.currency_code }}
                          </span>
                        </tr>
                        <hr/>
                        <tr class="total">
                          <span>
                            Current / Remaining Balance :
                            $ {{ ((bookingDetail.total - sum) * bookingDetail.orderdetail.currency_value).toFixed(2) }}
                            {{ bookingDetail.orderdetail.currency_code }}
                          </span>
                        </tr>
                      </tbody>
                    </table>

                    <div v-else class="text-center" >
                      <b-spinner label="Loading..." variant="success"/>
                    </div>

                  </div>
                </article>

                <!-- Transactions -->
                <article class="card mb-2 rounded-0" v-if="sum != 0 && !isloadingTransactions">
                  <div class="card-body">
                    <table class="table animate__animated animate__bounce">
                      <tbody>
                        <tr >
                        <h4 class="listPayTitle">Transactions</h4>
                        <div>
                          <table class="listPay">
                            <tr>
                              <th>Date</th>
                              <th>Num</th> 
                              <th>Amount</th>
                              <th>Currency</th>
                              <th>Reference</th>
                              <th>Status</th>
                            </tr>
                            <tr
                              v-for="listPay in listPayOrders"
                              :key="listPay.id"
                            >
                              <td>{{listPay.date_transaction_bank ? listPay.date_transaction_bank : ""}}</td>
                              <td>{{listPay.cc_bank}}</td>

                              <td>$ {{ listPay.paid_amount }}</td>
                              <td>{{ listPay.paid_currency.code }}</td>
                              <td>{{listPay.folio_bank ? listPay.folio_bank : ""}}</td>
                              <td>{{ listPay.transactionstatus.name }}</td>
                            </tr>
                          </table>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </article>
                <article class="card mb-2 rounded-0" v-else-if="isloadingTransactions">
                  <div class="card-body text-center" >
                    <b-spinner label="Loading..." variant="success"/>
                  </div>
                </article>

                <!-- Information -->
                <article class="card mb-2 rounded-0">
                  <div class="card-header">
                    <div class="col-md-12">
                      <h4 class="listPayTitle mb-0" style="0.75s infinite ease-in-out">
                        Your information
                      </h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table animate__animated animate__bounce">
                      <tbody>
                        <tr class="text-small-cart">
                          <td>
                            <strong> Name </strong>
                            <br />
                            <span>
                              {{ bookingDetail.givenname }}
                              {{ bookingDetail.surname }}
                            </span>
                          </td>
                        </tr>
                        <tr class="text-small-cart">
                          <td>
                            <strong> E-mail </strong>
                            <br />
                            <span>
                              {{ bookingDetail.email }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </article>

              </div>

              <div v-else>
                <div class="alert alert-danger" role="alert">
                  No information found
                </div>
              </div>

            </b-col>

            <!-- 2da columna -->

            <div v-if="urlbank && !orderAlreadyPaid"
              class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-7 containerIframe"
            >
              <iframe class="responsive-iframe" :src="urlbank"> </iframe>
            </div>

            <div v-else-if="bookingDetail.total - sum != 10 && sum != 10"
              class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-7 "
            >
              <div class="checkout-options">
                <b-card>
                  <b-row>
                    <b-col>
                      <ValidationObserver
                        tag="form"
                        @submit.prevent="onSubmit"
                        ref="observer"
                      >
                        <ValidationProvider rules="required" name="balance">
                          <b-input-group
                            slot-scope="{ valid, errors }"
                            prepend="Amount to pay"
                          >
                            <b-form-input
                              type="number"
                              step=".01"
                              :placeholder="parseFloat(balancePaid).toFixed(2) + ' USD'"
                              :state="errors[0] ? false : valid ? true : null"
                              v-model="amount"
                              @input="validTotal()"
                              locale="en"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>
                              {{ paymentError(valid) }}
                            </b-form-invalid-feedback>
                          </b-input-group>
                        </ValidationProvider>
                      </ValidationObserver>
                    </b-col>
                    <b-col md="4" v-if="isSalescoreGroup">
                      <validation-provider name="Divisa" rules="required">
                        <b-form-group slot-scope="{ valid, errors }">
                          <b-input-group 
                          prepend="Currency"
                          >
                            <b-form-select
                              :state="errors[0] ? false : valid ? true : null"
                              v-model="currencyValue"
                              :clearable="false"
                            >
                              <option v-for="cur in currencies" :key="cur.id" :value="cur">
                                {{ cur.code }}
                              </option>
                            </b-form-select>
                          </b-input-group>
                          <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="2">
                      <div class="float-right">
                        <div v-if="!loadvalid">
                          <b-button
                            variant="primary"
                            :disabled="loadvalid "
                            @click="getLinkPay()"
                          > Pay now
                          </b-button>
                        </div>
                        <div v-else class="text-center" >
                          <b-spinner label="Loading..." variant="success"/>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" v-if="!!currencyValue && currencyValue.value > 1">
                      <span class="font-weight-bolder">Total Pay {{ currencyValue.code }}:</span>
                      ${{ totalDivisa }}
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </div>

            <div v-else-if="sum != 0 && bookingDetail.total - sum == 0"
              class="col-12 col-md-7 col-sm-12"
            >
              <div class="checkout-options">
                <b-card>
                  <div class="w-100 text-center">
                    <h2 class="mb-1">This order is paid 😊👌</h2>
                    <img
                      src="/img/error.7cce96d0.svg"
                      alt="Error page"
                      class="img-fluid"
                    />
                  </div>
                </b-card>
              </div>
            </div>

            <div v-else class="col-12 col-md-7 col-sm-12">
              <div class="checkout-options" v-if="validNull==false">
                <b-card>
                  <div class="w-100 text-center">
                    <h2 class="mb-1">This order was not paid 🕵🏻‍♀️</h2>
                    <img
                      src="/img/error.7cce96d0.svg"
                      alt="Error page"
                      class="img-fluid"
                    />
                  </div>
                </b-card>
              </div>
              <div v-else class="text-center loadingInitiate" >
                <b-spinner label="Loading..." variant="success"/>
              </div>
            </div>

          </b-row>
        </b-container>
      </div>
    </div>
    <FooterPayment/>
  </div>
</template>
  
  
<script>
import HeaderPayment from "@/modules/shop/components/HeaderPayment"
import FooterPayment from "@/modules/shop/components/FooterPayment"
import { mapState, mapActions, mapMutations } from "vuex"
import { toJson } from "@/helpers/helpers"

export default {
  async mounted() {
    if (this.order) this.setInitialData()
    this.balancePaid=this.bookingDetail?.orderdetail?.balance || 0
  },
  components: {
    HeaderPayment,
    FooterPayment,
  },

  data() {
    return {
      loadvalid:false,
      order: this.$route.query.order,
      urlbank: this.$route.query.urlbank,
      sum: 0,
      amount: "",
      validNull: true,
      currencyValue: null,
      listPayOrders: [],
      balancePaid:0.00,
      balanceId:0,
      isloadingTransactions: false
    };
  },
  computed: {
    ...mapState("shop", ["cart"]),

    ...mapState("groups", ["bookingDetail","roomsTransactions","isloadingProcess"]),
    ...mapState("start", ["currencies"]),
    ...mapState("mySales", ["errorMessageApi", "dataPaymentLink"]),

    total() {
      return (Math.round(this.amount * 100) / 100).toFixed(2);
    },
    tc() {
      const currencieValor = this.cart.cliente.currency ? this.cart.cliente.nameprefix : 1
      return (Math.round(currencieValor * 100) / 100).toFixed(2);
    },
    totalDivisa() {
      const amount = this.total || 0
      const currencieValor = this.currencyValue?.value || 1

      return (Math.round(parseFloat(amount) * parseFloat(currencieValor) * 100) / 100).toFixed(2)
    },
    orderAlreadyPaid(){
      const currentCurrency = this.currencies.find((currency) => currency.code === this.bookingDetail.orderdetail.currency_code)
      return ((this.bookingDetail?.total - this.sum) * currentCurrency?.value).toFixed(2) == 0.00
    },
    isSalescoreGroup(){
      return this.bookingDetail?.orderdetail?.ordertype == 'salescoregroup'
    }
  },
  methods: {
    ...mapActions("groups", ["fetchDataOrderBooking","fetchTransactionsBooking"]),
    ...mapActions("mySales", ["fetchTransactionPay"]),
    ...mapActions("start", ["fetchCurrencies"]),
    ...mapMutations("shop", ["setCurrency"]),
    ...mapMutations("groups", ["setRoomsTransactions","setLoadingProcess","setRoomsInfo"]),
    ...mapMutations('start',['setCurrencies']),

    async setInitialData(){
      await this.getDataOrder()
      this.getDaSum()

      const currencies = await this.fetchCurrencies()
      this.setCurrencies(currencies) //muto state currencies en start

      this.setCurrencyCustomer()
      this.currencyValue = this.currencies.find((currency) => currency.code === this.bookingDetail.orderdetail.currency_code)
    },
    setCurrencyCustomer(){
      const cliente = this.cart.cliente
      const { currency } = cliente
      const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ) )
      if( !currency ) this.setCurrency( divisa )
    },
    async getDataOrder() {
      await this.fetchDataOrderBooking(this.order)
      this.isloadingTransactions = true
      await this.fetchTransactionsBooking(this.bookingDetail.orderdetail.orderdetail_id)
      this.isloadingTransactions = false
      this.order=this.bookingDetail.orderdetail.order_id
      this.balanceId=this.bookingDetail.orderdetail.orderdetail_id
    },
    getDaSum() {
      this.roomsTransactions.forEach((list) => {
        if (list.transactionstatus.name == "Approved") this.sum += parseFloat(list.amount)
        if (list.transactionstatus.name != "Pending") this.listPayOrders.push(list)
      })
    },
    async getLinkPay() {
      const payload = { amount: this.amount, order: this.order, detail:this.balanceId, system: 'salescoregroup', currencycode: this.currencyValue?.code || this.bookingDetail?.orderdetail?.currency_code }

      this.loadvalid=true
      await this.fetchTransactionPay(payload)
      this.loadvalid=false
      this.urlbank = this.dataPaymentLink.url
    },
    calcularImportes() {
      const currencieValor = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1
      this.divisaTotal = (Math.round(parseFloat(this.cart.totalBreakdown.total) * parseFloat(currencieValor) * 100) / 100).toFixed(2)
      this.setCurrency(this.cart.cliente.currency)
    },
    validTotal() {
      const total = parseFloat(this.amount);
      const balance = this.bookingDetail.total - this.sum
      if (total <= balance) this.validNull = false
      else this.validNull = true
    },
    paymentError(valid){
      if (!valid) return 'This field is required'
      return true
    }
  },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
iframe {
  overflow: hidden;
}
.roomSelect {
  cursor: pointer;
  padding: 11px!important;
}
.containerIframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 619px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.fixed-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 40%;
  border: none;
}

.alert.alert-danger {
  padding: 9px 0 7px 13px;
}

.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1440px;
}

.misc-wrapper {
  display: block;
  flex-basis: 100%;
  min-height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.container {
  padding-bottom: 62px;
}

.misc-wrapper {
  position: relative;
  height: 100%;
  display: contents;
}
#app > div {
  display: inline-table;
}

tr.total {
  text-align: center;
  padding: 14px 0 0 0;
  display: block;
  font-weight: 600;
  font-size: 18px;
}

tr.text-small-cart.row {
  text-align: center;
}

table.payment {
  background: #fff;
  padding: 27px 12px 25px 15px;
  border-collapse: initial;
  text-align: center;
  margin: 31px auto 105px;
  width: 71%;
}

.card-img-top {
  width: 100%;
  height: 25vh;
  object-fit: cover;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0rem;
  min-height: 0rem;
  display: flex;
  align-items: center;
}
tr.listPay {
  text-align: center;
  font-size: 12px;
}

.listPay th, .listPay td {
  padding: 8px;
  font-size: 12px;
  vertical-align: middle;
}
.listPayTitle {
  text-align: center;
  margin-bottom: 10px;
}
ul.list-unstyled {
  text-align: right;
}
table.content.booking {
  margin: 12px 0 0 0;
}

table.content.booking span {
  padding-right: 5px;
}
table.content.booking tr,
table.content.booking td {
  border: 0;
  padding: 6px 15px 6px 15px;
}

.titleProduc {
  font-weight: bold;
  margin: 3px 0 10px 0;
  display: block;
}

table.listPay {
  margin: 0 auto;
}
.active {
  background: #cccc;
}
.roomSelect{
  cursor: pointer;
}
#roomundefined{
  cursor:default ;
}
.bg-gradient-success, .btn-gradient-success {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient( 47deg, #339e62, #1f8a4e);
  background-repeat: repeat-x;
  background-repeat: repeat;
}
.ddine{
  display: inline-block;
}
.loadingInitiate {
    margin: 20px 0 0 0;
}

@media (max-width: 1380px) {
.listPay th, .listPay td {
  padding: 5px;
  font-size: 10px;
  vertical-align: middle;
}
}
</style>
<style scoped src="@/assets/css/success.css"></style>
  